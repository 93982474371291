<template>
    <page-title
        title="社員一覧"
        icon="bi-person-fill"
    >
        <button-go-create
            v-if="$store.getters['auth/canManageEmployee']()"
            :to="{name: 'EmployeeAdd'}"
        ></button-go-create>
    </page-title>

    <section class="section">
        <div class="row align-items-end">
            <div class="col-6">
                <label>氏名</label>
                <form-input
                    v-model="condition.photographer_name"
                ></form-input>
            </div>
            <div class="form-group col-md-4">
                <form-select
                    v-model="condition.employee_type"
                    :options="employee_types"
                    empty_option="-- 全て --"
                ></form-select>
            </div>
            <div class="col-3">
                <button-search
                    @click="search()"
                    :disabled="searchLoading"
                ></button-search>
            </div>
        </div>
    </section>

    <section class="section">
        <inline-loader v-if="searchLoading"></inline-loader>
        <template v-else>
            <template v-if="employees.length">
                <table class="table table-striped mb-4">
                    <thead>
                        <tr class="table-primary">
                            <th class="col-md-3 text-center">氏名</th>
                            <th class="col-md-2 text-center">略称</th>
                            <th class="col-md-2 text-center">カメラマン</th>
                            <th class="col-md-12 text-center">権限</th>
                            <th class="col-md-2 text-center">並び順</th>
                            <th class="col-md-3"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="employee in employees" :key="employee">
                            <tr>
                                <td class="text-center">{{ employee.photographer_name }}</td>
                                <td class="text-center">{{ employee.shortened_name }}</td>
                                <td class="text-center">
                                    <i class="bi bi-camera-fill" v-if="employee.is_photographer === is_photographer"></i>
                                </td>
                                <td class="">{{ employee.roles_display }}</td>
                                <td class="text-center">{{ employee.priority }}</td>
                                <td class="text-center">
                                    <button-go-update
                                        v-if="$store.getters['auth/canManageEmployee']()"
                                        :to="{name: 'EmployeeEdit', params: {id: employee.photographer_id}}"
                                    ></button-go-update>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                    <tfoot></tfoot>
                </table>
                <paginator :meta="paginator" @move="search"></paginator>
            </template>
            <alert-no-record v-else></alert-no-record>
        </template>
    </section>
</template>

<script>
import { onMounted, ref, reactive } from 'vue';
import { useStore } from 'vuex';
import http from '@/http/index';
import EmployeeType from '@/models/enums/employee/employee-type'
import IsPhotographer from '@/models/enums/employee/is-photographer'
import Employee from '@/models/entities/employee';
import PageTitle from '@/components/PageTitle';
import ButtonGoCreate from '@/components/buttons/ButtonGoCreate';
import ButtonSearch from '@/components/buttons/ButtonSearch';
import ButtonGoUpdate from '@/components/buttons/ButtonGoUpdate';
import FormInput from '@/components/forms/FormInput';
import FormSelect from '@/components/forms/FormSelect';
import InlineLoader from '@/components/tools/InlineLoader';
import Paginator from '@/components/tools/Paginator';
import AlertNoRecord from '@/components/tools/AlertNoRecord';


export default {
    name: 'EmployeeList',
    components: {
        PageTitle,
        ButtonGoCreate,
        ButtonSearch,
        ButtonGoUpdate,
        FormInput,
        FormSelect,
        InlineLoader,
        Paginator,
        AlertNoRecord
    },
    setup() {
        const employees = ref([]);
        const employee_types = EmployeeType.options();
        const is_photographer = IsPhotographer.PHOTOGRAPHER;

        //検索
        const store = useStore();
        const searchLoading = ref(0);
        const condition = reactive({
            photographer_name: null,
            employee_type: null,
            page: 1,
        });
        const paginator = reactive({
            current_page: null,
            from: null,
            last_page: null,
            links: [],
            path: null,
            per_page: null,
            to: null,
            total: null,
        });
        const search = (page = 1) => {
            searchLoading.value++;
            condition.page = page;

            //表示データ初期化
            employees.value.splice(0);

            http.get('/employees', {
                params: condition
            })
            .then(response => {
                for (let row of response.data.data) {
                    employees.value.push(new Employee(row));
                }
                //ページネーション用
                let meta = response.data.meta;
                paginator.current_page = meta.current_page;
                paginator.from = meta.from;
                paginator.last_page = meta.last_page;
                paginator.links = meta.links;
                paginator.path = meta.path;
                paginator.per_page = meta.per_page;
                paginator.to = meta.to;
                paginator.total = meta.total;

                //検索条件を保存
                store.commit('condition/setSearchCondition', {
                    page: 'EmployeeList',
                    condition: condition
                });
            })
            .finally(() => {
                searchLoading.value--;
            });
        };

        onMounted(() => {
            //storeに前回の検索条件があれば
            let {photographer_name, employee_type, page} = store.getters['condition/merge']('EmployeeList', condition);
            condition.photographer_name = photographer_name;
            condition.employee_type = employee_type;
            condition.page = page;
            search();
        });

        return {
            employees,
            employee_types,
            is_photographer,
            searchLoading,
            condition,
            paginator,
            search
        }

    }
}
</script>

<style scoped>

</style>
