import Enum from "../enum";

/**
 * 社員 検索時カメラマンかどうか
 */
class EmployeeType extends Enum {
    static ONLY_PHOTOGRAPHER = 1; // カメラマンのみ
    static EXCEPTING_PHOTOGRAPHER = 2; // カメラマン以外

    static values() {
        return {
            [this.ONLY_PHOTOGRAPHER]: 'カメラマンのみ',
            [this.EXCEPTING_PHOTOGRAPHER]: 'カメラマン以外',
        }
    }
}

export default EmployeeType;
